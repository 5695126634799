import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {from, Observable, of} from 'rxjs';
import {switchMap} from "rxjs/operators";
import {Amplify} from "aws-amplify";
import {fetchAuthSession} from 'aws-amplify/auth';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const amplifyConfig = Amplify.getConfig();

    const restConfig = amplifyConfig.API!.REST!;
    const coreApiEndpoint = restConfig['CalendallApi']!.endpoint;
    const analyticsApiEndpoint = restConfig['CalendallAnalyticsApi']!.endpoint;
    const publicApiEndpoint = restConfig['CalendallSignUpApi']!.endpoint;

    if (req.url.includes('s3') && !req.url.includes('execute-api')) {
      return next.handle(req);
    }

    if (this.isPublicApi(req)) {
      return of(publicApiEndpoint).pipe(
        switchMap((signUpBaseUrl) => {
            return next.handle(req.clone({
              url: `${signUpBaseUrl}/${req.url}`
            }));
          }
        )
      )
    } else
      return from(fetchAuthSession())
        .pipe(switchMap((session) => {
          const httpHeaders = new HttpHeaders().set(
            'Authorization',
            `Bearer ${session?.tokens?.idToken?.toString() || ''}`,
          );
          const url = (this.isAnalyticsApi(req) ? `${analyticsApiEndpoint}/${req.url}` : `${coreApiEndpoint}/${req.url}`).replace('api/api', 'api');
          return next.handle(req.clone({
            headers: httpHeaders,
            url: url
          }));
        }));
  }

  private isAnalyticsApi(req: HttpRequest<any>) {
    return req.url.includes('analytics');
  }

  private isPublicApi(req: HttpRequest<any>) {
    return req.url.includes('signup/tenants')
      || req.url.includes('signup/self')
      || req.url.includes('validate-promo-code')
      || req.url.includes('booking/');
  }
}
