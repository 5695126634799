import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'centsToEur'
})
export class CentsToEurPipe implements PipeTransform {

  transform(cents?: number): number | undefined {
    if (cents === undefined) return undefined;
    else return cents / 100;
  }

}
