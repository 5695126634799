import { AuthError } from '../AuthError.mjs';
import { AmplifyErrorCode } from '@aws-amplify/core/internals/utils';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
function assertServiceError(error) {
  if (!error || error.name === 'Error' || error instanceof TypeError) {
    throw new AuthError({
      name: AmplifyErrorCode.Unknown,
      message: 'An unknown error has occurred.',
      underlyingError: error
    });
  }
}
export { assertServiceError };
