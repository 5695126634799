import {createAction, props} from "@ngrx/store";
import {CalendarDisplaySettings, CognitoUserAttributes, UserSettings} from "../models/current-user";


export const login = createAction(
  '[LoginComponent] login',
  props<{ cognitoUserAttributes: CognitoUserAttributes }>()
)

export const logout = createAction(
  '[SideNavComponent] logout',
)

export const saveCalendarDisplaySettings = createAction(
  '[CalendarDisplaySettingsComponent] save CalendarDisplaySettings',
  props<{ calendarDisplaySettings: CalendarDisplaySettings }>()
)

export const userDetailsLoaded = createAction(
  '[LoginEffect] user details loaded',
  props<UserSettings>()
)
