<mat-form-field class="w-100">
  <mat-label>{{label}}</mat-label>
  <mat-autocomplete #time="matAutocomplete"
                    [autoActiveFirstOption]="true"
                    [autoSelectActiveOption]="true"
                    (optionSelected)="onChange($event.option.value)"
  >

    <mat-option *ngFor="let time of filteredTimes$ | async" [value]="time">{{time}}</mat-option>
  </mat-autocomplete>
  <input matInput
         [formControl]=selectedTimeFormControl
         type="text"
         [matAutocomplete]="time"
         [required]="required"
         (keyup)="keyUp()"
  >
</mat-form-field>
