import { assertTokenProviderConfig, assertOAuthConfig, AuthAction } from '@aws-amplify/core/internals/utils';
import { oAuthStore } from './oAuthStore.mjs';
import { completeOAuthFlow } from './completeOAuthFlow.mjs';
import { getAuthUserAgentValue } from '../../../../utils/getAuthUserAgentValue.mjs';
import { getRedirectUrl } from './getRedirectUrl.mjs';
import { handleFailure } from './handleFailure.mjs';
import '../refreshAuthTokens.mjs';
import '../../tokenProvider/errorHelpers.mjs';
import '@aws-amplify/core';
import { cognitoUserPoolsTokenProvider } from '../../tokenProvider/tokenProvider.mjs';
import { addInflightPromise } from './inflightPromise.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const attemptCompleteOAuthFlow = async authConfig => {
  try {
    assertTokenProviderConfig(authConfig);
    assertOAuthConfig(authConfig);
    oAuthStore.setAuthConfig(authConfig);
  } catch (_) {
    // no-op
    // This should not happen as Amplify singleton checks the oauth config key
    // unless the oauth config object doesn't contain required properties
    return;
  }
  // No inflight OAuth
  if (!(await oAuthStore.loadOAuthInFlight())) {
    return;
  }
  // when there is valid oauth config and there is an inflight oauth flow, try
  // to block async calls that require fetching tokens before the oauth flow completes
  // e.g. getCurrentUser, fetchAuthSession etc.
  const asyncGetSessionBlocker = new Promise((resolve, _) => {
    addInflightPromise(resolve);
  });
  cognitoUserPoolsTokenProvider.setWaitForInflightOAuth(() => asyncGetSessionBlocker);
  try {
    const currentUrl = window.location.href;
    const {
      loginWith,
      userPoolClientId
    } = authConfig;
    const {
      domain,
      redirectSignIn,
      responseType
    } = loginWith.oauth;
    const redirectUri = getRedirectUrl(redirectSignIn);
    await completeOAuthFlow({
      currentUrl,
      clientId: userPoolClientId,
      domain,
      redirectUri,
      responseType,
      userAgentValue: getAuthUserAgentValue(AuthAction.SignInWithRedirect)
    });
  } catch (err) {
    await handleFailure(err);
  }
};
export { attemptCompleteOAuthFlow };
