import {Directive, HostListener, Injectable} from '@angular/core'
import {Location} from '@angular/common'
import {NavigationEnd, Router} from '@angular/router'

/**
 * Keeps track of the navigation history of the Router
 * in order to allow to navigate back to the previous location.
 */
@Injectable({providedIn: 'root'})
export class NavigationService {
  private history: string[] = []

  constructor(private router: Router, private location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects)
      }
    })
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back()
    } else {
      this.router.navigateByUrl('/')
    }
  }
}

@Directive({
  selector: '[backButton]',
})
export class BackButtonDirective {
  constructor(private navigation: NavigationService) {
  }

  @HostListener('click')
  onClick(): void {
    this.navigation.back()
  }
}
