import {MatDialogConfig} from "@angular/material/dialog";

export const defaultDialogConfig = (data?: any) => {
  const dialogConfig = new MatDialogConfig();
  dialogConfig.autoFocus = false;
  dialogConfig.disableClose = true; // avoid closing when user clicks outside the dialog
  dialogConfig.width = '80vw';
  dialogConfig.maxWidth = '80em';
  dialogConfig.data = data;
  return dialogConfig;
}
