<h2 mat-dialog-title>Datei hochladen</h2>
<mat-dialog-content class="container">
    <form [formGroup]="form">
        <div class="row">
            <mat-form-field class="col-12 col-md-6">
                <mat-label>Dateiname</mat-label>
                <input matInput placeholder="File Name" formControlName="fileName">
            </mat-form-field>
        </div>
    </form>
    <app-busy-indicator [state]="busy$ | async"></app-busy-indicator>
</mat-dialog-content>
<mat-dialog-actions class="button-bar">
    <button mat-button (click)="onCancel()">Abbrechen</button>
    <button mat-flat-button color="primary" (click)="onUpload()" [disabled]="!form.valid">Hochladen</button>
</mat-dialog-actions>
