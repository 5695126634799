import {Component, Inject, OnInit} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {Customer} from "@app/shared/models/customer";
import {HttpErrorResponse} from "@angular/common/http";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FlattenedAppointment} from "@app/shared/models/appointment";
import {
  GenericConfirmDialogComponent
} from "@app/shared/components/generic-deletion-confirm-dialog/generic-confirm-dialog.component";
import {defaultDialogConfig} from "@app/shared/dialog-util";
import {CustomerEntityService} from "@app/shared/services/customer-entity.service";
import {AppointmentEntityService} from "../../../services/appointment-entity.service";

@Component({
  selector: 'app-edit-customer',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.scss']
})
export class CustomerEditComponent implements OnInit {

  customer: Customer;

  constructor(
    private customerEntityService: CustomerEntityService,
    private appointmentEntityService: AppointmentEntityService,
    private dialogRef: MatDialogRef<CustomerEditComponent>,
    private deleteDialogRef: MatDialog,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: { customer: Customer, appointments: FlattenedAppointment[] }
  ) {
    this.customer = data.customer;
  }

  ngOnInit(): void {
  }

  handleCloseDialog(request: Partial<Customer> | null) {
    if (!request) {
      this.dialogRef.close();
      return;
    }

    request.id = this.customer.id;
    this.customerEntityService.update(request).subscribe({
      next: (customer: Customer) => {
        this.appointmentEntityService.updateCustomerSettings(customer.id, customer).subscribe();
        this.snackBar.open(
          'Kunde erfolgreich gespeichert.',
          undefined,
          {duration: 3000});
        this.dialogRef.close(customer);
      },
      error: (e: HttpErrorResponse) => {
        this.snackBar.open(
          'Beim Speichern des Kunden ist ein Fehler aufgetreten',
          undefined,
          {duration: 3000});
        this.dialogRef.close();
      }
    });
  }

  handleDeleteCustomer(id: string) {
    const dialogConfig = defaultDialogConfig();
    dialogConfig.maxWidth = '40em';
    dialogConfig.data = {
      text: [
        'Wollen Sie wirklich alle persönlichen Daten dieses Kunden aus dem System löschen? Diese Aktion kann nicht rückgängig gemacht werden.',
        'Personenunabhängige Daten wie Termine und Umsätze des Kunden bleiben anonymisiert erhalten.'
      ],
    }

    let deleteDialogRef = this.deleteDialogRef.open(GenericConfirmDialogComponent, dialogConfig);

    deleteDialogRef.afterClosed().subscribe(deleteCustomer => {
      if (deleteCustomer) {
        this.customerEntityService.delete(this.customer.id).subscribe({
          next: () => {
            this.snackBar.open('Kunde erfolgreich gelöscht.', undefined, {duration: 3000});
            this.dialogRef.close();
          },
          error: (err) => {
            this.snackBar.open(
              'Beim Löschen des Kunden ist ein Fehler aufgetreten.',
              undefined,
              {duration: 3000});
          }
        });
      }
    });
  }

}
