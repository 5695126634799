import {Frequency, RRule, rrulestr} from "rrule";
import {addYears, endOfDay, parseISO, startOfMinute} from "date-fns";
import {
  AppointmentEntity,
  FlattenedAppointment,
  RecurrenceException,
  RecurringAppointmentType
} from "./models/appointment";

export type RecurrenceRuleInput = {
  recurrenceFrequency: Frequency | null,
  recurrenceInterval: number,
  startDateTime: Date,
  recurrenceEnd: Date | null
};

export function asLocalDate(s: Date) {
  // represent Date object as local time by stripping off timezone information, see https://www.npmjs.com/package/rrule#timezone-support
  return new Date(Date.UTC(s.getFullYear(), s.getMonth(), s.getDate(), s.getHours(), s.getMinutes()));
}

export function asZonedDate(s: Date) {
  // RRule outputs dates as UTC dates, have to convert to current timezone by instantiating a new Date()
  return new Date(s.getUTCFullYear(), s.getUTCMonth(), s.getUTCDate(), s.getUTCHours(), s.getUTCMinutes());
}

export function toRecurrenceRuleString(rule: RecurrenceRuleInput): string | undefined {

  if (rule.recurrenceFrequency === null || rule.recurrenceFrequency < 0) return;

  const options = {
    freq: rule.recurrenceFrequency,
    interval: rule.recurrenceInterval,
    dtstart: asLocalDate(rule.startDateTime),
    tzid: Intl.DateTimeFormat().resolvedOptions().timeZone,
    until: rule.recurrenceEnd ? asLocalDate(rule.recurrenceEnd) : null
  };
  return new RRule({...options}).toString();
}

export function updateRecurrenceRule(oldRecurrenceRuleString: string, newStart: Date) {
  const recurrenceRule = parseRRule(oldRecurrenceRuleString);
  return toRecurrenceRuleString({
    recurrenceFrequency: recurrenceRule.options.freq,
    recurrenceEnd: recurrenceRule.options.until,
    recurrenceInterval: recurrenceRule.options.interval,
    startDateTime: newStart
  });
}

export function parseRRule(recurrenceRule: string): RRule {
  try {
    return rrulestr(recurrenceRule) as RRule;
  } catch (e) {
    throw new Error('Invalid recurrence rule')
  }
}

export const RECURRENCE_MAX_DATE = addYears(endOfDay(new Date()), 10);

export function expandRecurrence(
  appointment: AppointmentEntity,
): Array<FlattenedAppointment> {
  if (!appointment.recurrenceRule) return [{
    ...appointment,
    start: parseISO(appointment.start),
    recurrence: {
      type: RecurringAppointmentType.SingleAppointment
    },
    employeeIds: appointment.employeeIds ?? []
  }];

  const recurrenceRule = parseRRule(appointment.recurrenceRule);
  const recurrenceExceptionItems = appointment.recurrenceExceptions ?? [];

  const mapOfExceptions = toMap(recurrenceExceptionItems);
  recurrenceRule.options.until = recurrenceRule.options.until || RECURRENCE_MAX_DATE;
  const startDates: Array<Date> = recurrenceRule.between(
    recurrenceRule.options.dtstart,
    recurrenceRule.options.until,
    true
  ).map(it => asZonedDate(it));

  const recurrencesOrUndefined: Array<FlattenedAppointment | undefined> = startDates.map(startDate => {
    return recurrenceOrException(startDate, mapOfExceptions, appointment);
  });
  return recurrencesOrUndefined.filter(
    appointment => appointment !== undefined
  ) as Array<FlattenedAppointment>;
}

export function toMap(recurrenceExceptionItems: Array<RecurrenceException>) {
  const mapOfExceptions = new Map<string, RecurrenceException>();
  recurrenceExceptionItems.forEach(it => {
    mapOfExceptions.set(it.regularStart, it);
  });
  return mapOfExceptions;
}

export function recurrenceOrException(
  startDate: Date,
  mapOfExceptions: Map<string, RecurrenceException>,
  it: AppointmentEntity
): FlattenedAppointment | undefined {
  const start = startOfMinute(startDate).toISOString();
  const recurrenceExceptionItem = mapOfExceptions.get(start);
  if (recurrenceExceptionItem) {
    return recurrenceException(recurrenceExceptionItem, it);
  } else return {
    ...it,
    recurrence: {
      type: RecurringAppointmentType.RegularRecurrence,
      recurrenceRule: it.recurrenceRule!
    },
    start: startOfMinute(startDate),
    employeeIds: it.employeeIds ?? [],
  }
}

function recurrenceException(recurrenceException: RecurrenceException, it: AppointmentEntity): FlattenedAppointment | undefined {
  if (recurrenceException.replacement) {
    return {
      ...it,
      ...recurrenceException.replacement,
      recurrence: {
        type: RecurringAppointmentType.RecurrenceException,
        regularStart: recurrenceException.regularStart
      },
      start: parseISO(recurrenceException.replacement.start ?? it.start),
      employeeIds: recurrenceException.replacement.employeeIds ?? it.employeeIds ?? []
    }
  } else {
    // if the exception has no replacement, this appointment is just skipped
    return undefined;
  }
}
