import { composeServiceApi } from '@aws-amplify/core/internals/aws-client-utils/composers';
import { cognitoUserPoolTransferHandler, defaultConfig, buildHttpRpcRequest, getSharedHeaders } from './base.mjs';
import { parseJsonError, parseJsonBody } from '@aws-amplify/core/internals/aws-client-utils';
import { assertServiceError } from '../../../../../errors/utils/assertServiceError.mjs';
import { AuthError } from '../../../../../errors/AuthError.mjs';
const buildUserPoolSerializer = operation => (input, endpoint) => {
  const headers = getSharedHeaders(operation);
  const body = JSON.stringify(input);
  return buildHttpRpcRequest(endpoint, headers, body);
};
const buildUserPoolDeserializer = () => {
  return async response => {
    if (response.statusCode >= 300) {
      const error = await parseJsonError(response);
      assertServiceError(error);
      throw new AuthError({
        name: error.name,
        message: error.message
      });
    } else {
      const body = await parseJsonBody(response);
      return body;
    }
  };
};
const handleEmptyResponseDeserializer = () => {
  return async response => {
    if (response.statusCode >= 300) {
      const error = await parseJsonError(response);
      assertServiceError(error);
      throw new AuthError({
        name: error.name,
        message: error.message
      });
    } else {
      return undefined;
    }
  };
};
const initiateAuth = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('InitiateAuth'), buildUserPoolDeserializer(), defaultConfig);
const revokeToken = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('RevokeToken'), buildUserPoolDeserializer(), defaultConfig);
const signUp = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('SignUp'), buildUserPoolDeserializer(), defaultConfig);
const confirmSignUp = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('ConfirmSignUp'), buildUserPoolDeserializer(), defaultConfig);
const forgotPassword = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('ForgotPassword'), buildUserPoolDeserializer(), defaultConfig);
const confirmForgotPassword = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('ConfirmForgotPassword'), buildUserPoolDeserializer(), defaultConfig);
const respondToAuthChallenge = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('RespondToAuthChallenge'), buildUserPoolDeserializer(), defaultConfig);
const resendConfirmationCode = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('ResendConfirmationCode'), buildUserPoolDeserializer(), defaultConfig);
const verifySoftwareToken = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('VerifySoftwareToken'), buildUserPoolDeserializer(), defaultConfig);
const associateSoftwareToken = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('AssociateSoftwareToken'), buildUserPoolDeserializer(), defaultConfig);
const setUserMFAPreference = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('SetUserMFAPreference'), buildUserPoolDeserializer(), defaultConfig);
const getUser = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('GetUser'), buildUserPoolDeserializer(), defaultConfig);
const changePassword = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('ChangePassword'), buildUserPoolDeserializer(), defaultConfig);
const confirmDevice = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('ConfirmDevice'), buildUserPoolDeserializer(), defaultConfig);
const forgetDevice = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('ForgetDevice'), handleEmptyResponseDeserializer(), defaultConfig);
const deleteUser = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('DeleteUser'), handleEmptyResponseDeserializer(), defaultConfig);
const getUserAttributeVerificationCode = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('GetUserAttributeVerificationCode'), buildUserPoolDeserializer(), defaultConfig);
const globalSignOut = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('GlobalSignOut'), buildUserPoolDeserializer(), defaultConfig);
const updateUserAttributes = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('UpdateUserAttributes'), buildUserPoolDeserializer(), defaultConfig);
const verifyUserAttribute = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('VerifyUserAttribute'), buildUserPoolDeserializer(), defaultConfig);
const updateDeviceStatus = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('UpdateDeviceStatus'), buildUserPoolDeserializer(), defaultConfig);
const listDevices = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('ListDevices'), buildUserPoolDeserializer(), defaultConfig);
const deleteUserAttributes = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('DeleteUserAttributes'), buildUserPoolDeserializer(), defaultConfig);
export { associateSoftwareToken, changePassword, confirmDevice, confirmForgotPassword, confirmSignUp, deleteUser, deleteUserAttributes, forgetDevice, forgotPassword, getUser, getUserAttributeVerificationCode, globalSignOut, initiateAuth, listDevices, resendConfirmationCode, respondToAuthChallenge, revokeToken, setUserMFAPreference, signUp, updateDeviceStatus, updateUserAttributes, verifySoftwareToken, verifyUserAttribute };
