import {Injectable} from '@angular/core';
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {Employee, UserPermissions} from "../models/employee";
import {map} from "rxjs/operators";
import {combineLatest, first, Observable, of} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {getWeekDay} from "../availability-utils";

@Injectable()
export class EmployeeEntityService extends EntityCollectionServiceBase<Employee> {
  activeEmployees$ = this.entities$.pipe(
    map(employees => employees.filter(it => !it.deleted))
  );
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private httpClient: HttpClient,
  ) {
    super('Employees', serviceElementsFactory);
  }

  findEmployeesForDate(param: { date$: Observable<Date> }) {
    return combineLatest([this.entities$, param.date$]).pipe(
      map(([employees, date]: [Employee[], Date]) => {
        const weekday = getWeekDay(date); // 0 - 6,  0 = Sunday
        return employees.filter(employee => employee.availabilityRules[weekday].length > 0)
      }),
    );
  }

  get(employeeId?: string) {
    if (!employeeId) return of(undefined);

    return this.entityMap$.pipe(
      map(entities => entities[employeeId]),
      first()
    );
  }

  getMany(employeeIds: string[]) {
    return this.entities$.pipe(
      map(entities => entities.filter(employee => employeeIds.includes(employee.id)))
    );
  }

  updatePermissions(employee: Employee, request: UserPermissions): Observable<Employee> {
    return this.httpClient.put<UserPermissions>(`user-permissions/${employee.id}`, request).pipe(
      map((permissions) => {
        const updatedEmployee: Employee = {
          ...employee, permissions
        }
        this.updateOneInCache(updatedEmployee);
        return updatedEmployee;
      })
    )
  }
}
