<mat-sidenav-container>
  <mat-sidenav #drawer
               [attr.role]="(isScreenLessThan600Px$ | async) ? 'dialog' : 'navigation'"
               [mode]="(isScreenLessThan600Px$ | async) ? 'over' : 'side'"
               [opened]="(isScreenLessThan600Px$ | async) === false">
    <ng-container
      *ngTemplateOutlet="navListTemplate">
    </ng-container>

    <div class="flex-fill"></div>
    <mat-menu #menu="matMenu" yPosition="above">
      <ng-container *ngIf="sessionService.currentUser$ | async; let currentUser">

        <ng-container>
          <a mat-menu-item routerLink="/aam" routerLinkActive="mdc-list-item--selected"><b>Einstellungen</b></a>
        </ng-container>

        <a mat-menu-item routerLink="/cam" routerLinkActive="mdc-list-item--selected">Kalender</a>

        <ng-container *ngIf="currentUser.permissions?.analytics">
          <a mat-menu-item routerLink="/analytics" routerLinkActive="mdc-list-item--selected">Statistik</a>
        </ng-container>

        <ng-container *ngIf="currentUser.permissions?.tenantOwner">
          <a mat-menu-item routerLink="/die" routerLinkActive="mdc-list-item--selected">Datenexport</a>
        </ng-container>

        <ng-container *ngIf="currentUser.permissions?.tenantManagement">
          <a mat-menu-item routerLink="/tm" routerLinkActive="mdc-list-item--selected">Tenants</a>
        </ng-container>

        <ng-container *ngIf="currentUser.permissions?.tenantManagement">
          <a mat-menu-item routerLink="/public/self-signup" routerLinkActive="mdc-list-item--selected">Registrierung</a>
        </ng-container>

        <mat-divider></mat-divider>
        <button mat-menu-item (click)="signOut()">Abmelden</button>
      </ng-container>
    </mat-menu>

    <div class="d-flex flex-row gap-1">
      <button class="m-2 px-2 py-4" mat-raised-button color="primary" aria-label="Menu button"
              [matMenuTriggerFor]="menu">
        Menü
      </button>
      <div class="d-flex flex-row align-items-center gap-1 pe-2">
        <mat-icon class="flex-grow-0 flex-shrink-0">account_circle</mat-icon>
        <div
          style="overflow: hidden; font-family: 'Archivo Black', sans-serif; font-weight: bold">
          {{ (currentUser$ | async)?.alias }}
        </div>
      </div>
    </div>
    <a routerLink="/cam/appointment-calendar" class="p-2">
      <img src="https://calendall-assets.s3.eu-central-1.amazonaws.com/calendall-logo-2024-02.png"
           class="calendall-logo"
           alt="logo"/>
    </a>
  </mat-sidenav>
  <mat-sidenav-content>
    <!--    <mat-toolbar class="d-md-none d-flex flex-row-reverse justify-content-between">-->

    <!--    </mat-toolbar>-->
    <main class="container-fluid pt-2">
      <router-outlet></router-outlet>
    </main>
    <div class="floating-mid-left" *ngIf="isScreenLessThan600Px$ | async">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-mini-fab
        color="primary"
        (click)="drawer.toggle()">
        <mat-icon aria-label="Side nav toggle icon">play_arrow</mat-icon>
      </button>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
