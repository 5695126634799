import {createReducer, on} from '@ngrx/store';
import {CalendarDisplaySettings, CognitoUserAttributes, DEFAULT_CALENDAR_SETTINGS} from "../models/current-user";
import {login, logout, saveCalendarDisplaySettings, userDetailsLoaded} from "./actions";
import {TenantFeatureConfig} from "../../tenant-management/models/tenant";
import {Employee} from "../models/employee";


export interface SharedModuleState {
  cognitoUserAttributes?: CognitoUserAttributes,
  calendarDisplaySettings: CalendarDisplaySettings,
  tenantFeatureConfig: TenantFeatureConfig,
  currentUser?: Employee
}


export const initialState: SharedModuleState = {
  cognitoUserAttributes: undefined,
  calendarDisplaySettings: DEFAULT_CALENDAR_SETTINGS,
  tenantFeatureConfig: {
    smsSending: false
  },
  currentUser: undefined
};

// export const reducers: ActionReducerMap<SharedModuleState> = {};

export const authReducer = createReducer(
  initialState,
  on(login, (state, {cognitoUserAttributes}) => ({...state, cognitoUserAttributes})),
  on(logout, () => initialState),
  on(userDetailsLoaded, (state, userSettings) =>
    ({
      ...state,
      currentUser: userSettings.employee,
      calendarDisplaySettings: {
        dayStartHour: userSettings.calendarDisplaySettings.dayStartHour || DEFAULT_CALENDAR_SETTINGS.dayStartHour,
        dayEndHour: userSettings.calendarDisplaySettings.dayEndHour || DEFAULT_CALENDAR_SETTINGS.dayEndHour,
        hourSegmentsDayView: userSettings.calendarDisplaySettings.hourSegmentsDayView || DEFAULT_CALENDAR_SETTINGS.hourSegmentsDayView,
        hourSegmentsWeekView: userSettings.calendarDisplaySettings.hourSegmentsWeekView || DEFAULT_CALENDAR_SETTINGS.hourSegmentsWeekView,
        orderByEmployeeId: userSettings.calendarDisplaySettings.orderByEmployeeId || DEFAULT_CALENDAR_SETTINGS.orderByEmployeeId,
        displayTimeLabelColumn: userSettings.calendarDisplaySettings.displayTimeLabelColumn || DEFAULT_CALENDAR_SETTINGS.displayTimeLabelColumn,
        appointmentAttributeOrder: userSettings.calendarDisplaySettings.appointmentAttributeOrder || DEFAULT_CALENDAR_SETTINGS.appointmentAttributeOrder
      },
      tenantFeatureConfig: userSettings.tenantFeatureConfig,
    })),
  on(saveCalendarDisplaySettings, (state, {calendarDisplaySettings}) => ({...state, calendarDisplaySettings})),
)
