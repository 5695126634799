import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  transform(bytes?: number, precision: number = 2): string {
    if (bytes === undefined) return '';
    if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '';
    if (bytes <= 0) return '0 KB';

    const kilobytes = bytes / 1024;
    const megabytes = kilobytes / 1024;
    if (megabytes < 1)
      return `${kilobytes.toFixed(precision)} KB`;
    else
      return `${megabytes.toFixed(precision)} MB`;
  }

}
