import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateDay',
})

export class TranslateDayPipe implements PipeTransform {

  private dayTranslations: { [key: string]: string } = {
    "monday": "Montag",
    "tuesday": "Dienstag",
    "wednesday": "Mittwoch",
    "thursday": "Donnerstag",
    "friday": "Freitag",
    "saturday": "Samstag",
    "sunday": "Sonntag"
  };

  transform(value: string): string | null {
    if (!value) {
      return null;
    }

    const lowerCaseValue = value.toLowerCase();
    return this.dayTranslations[value] || null;
  }

}
