import { decodeJWT } from '@aws-amplify/core/internals/utils';
import { AuthError } from '../../../errors/AuthError.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
function formLoginsMap(idToken) {
  const issuer = decodeJWT(idToken).payload.iss;
  const res = {};
  if (!issuer) {
    throw new AuthError({
      name: 'InvalidIdTokenException',
      message: 'Invalid Idtoken.'
    });
  }
  let domainName = issuer.replace(/(^\w+:|^)\/\//, '');
  res[domainName] = idToken;
  return res;
}
export { formLoginsMap };
