import { Component, Input } from '@angular/core';
import { parseISO } from 'date-fns';
import { FileResponse, FileResponseWithDownloadUrl } from '../../models/file';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmployeeEntityService } from '../../services/employee-entity.service';
import { Observable } from 'rxjs';
import { Employee } from '../../models/employee';
import { FileEntityService } from '../../services/file-entity.service';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
})
export class FileListComponent {
  @Input() files: Array<FileResponse>;

  constructor(
    private fileService: FileEntityService,
    private snackBar: MatSnackBar,
    private employeeService: EmployeeEntityService
  ) {}

  deleteFile(id: string) {
    this.fileService.delete(id, { isOptimistic: false }).subscribe({
      next: () => {
        this.files = this.files.filter((file) => file.id !== id);
        this.snackBar.open('Datei wurde gelöscht', 'OK', { duration: 3000 });
      },
      error: () => {
        this.snackBar.open('Datei konnte nicht gelöscht werden', 'OK', {
          duration: 3000,
        });
      },
    });
  }

  getCreatedAt(file: FileResponse): Date | undefined {
    return file.createdAt ? parseISO(file.createdAt) : undefined;
  }

  downloadFile(id: string) {
    this.fileService.getByKey(id, { isOptimistic: false }).subscribe({
      next: (file) => {
        console.log(file);
        window.open(
          (file as FileResponseWithDownloadUrl).presignedDownloadUrl,
          '_blank'
        );
      },
      error: () => {
        this.snackBar.open('Datei konnte nicht heruntergeladen werden', 'OK', {
          duration: 3000,
        });
      },
    });
  }

  getEmployee(uploadedByEmployeeId: string): Observable<Employee | undefined> {
    return this.employeeService.get(uploadedByEmployeeId);
  }
}
