import { ConsoleLogger, Amplify, clearCredentials, Hub, defaultStorage } from '@aws-amplify/core';
import { assertTokenProviderConfig, assertOAuthConfig, AMPLIFY_SYMBOL, AuthAction } from '@aws-amplify/core/internals/utils';
import { getAuthUserAgentValue } from '../../../utils/getAuthUserAgentValue.mjs';
import '../utils/refreshAuthTokens.mjs';
import { AuthError } from '../../../errors/AuthError.mjs';
import '../tokenProvider/errorHelpers.mjs';
import { tokenOrchestrator } from '../tokenProvider/tokenProvider.mjs';
import { revokeToken, globalSignOut as globalSignOut$1 } from '../utils/clients/CognitoIdentityProvider/index.mjs';
import { getRegion } from '../utils/clients/CognitoIdentityProvider/utils.mjs';
import { assertAuthTokensWithRefreshToken, assertAuthTokens } from '../utils/types.mjs';
import '@aws-crypto/sha256-js';
import { handleOAuthSignOut } from '../utils/oauth/handleOAuthSignOut.mjs';
import { OAUTH_SIGNOUT_EXCEPTION } from '../../../errors/constants.mjs';
import { DefaultOAuthStore } from '../utils/signInWithRedirectStore.mjs';
import '../../../types/Auth.mjs';
import '../../../Errors.mjs';
import '../../../common/AuthErrorStrings.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const logger = new ConsoleLogger('Auth');
/**
 * Signs a user out
 *
 * @param input - The SignOutInput object
 * @throws AuthTokenConfigException - Thrown when the token provider config is invalid.
 */
async function signOut(input) {
  const cognitoConfig = Amplify.getConfig().Auth?.Cognito;
  assertTokenProviderConfig(cognitoConfig);
  if (input?.global) {
    await globalSignOut(cognitoConfig);
  } else {
    await clientSignOut(cognitoConfig);
  }
  let hasOAuthConfig;
  try {
    assertOAuthConfig(cognitoConfig);
    hasOAuthConfig = true;
  } catch (err) {
    hasOAuthConfig = false;
  }
  if (hasOAuthConfig) {
    const oAuthStore = new DefaultOAuthStore(defaultStorage);
    oAuthStore.setAuthConfig(cognitoConfig);
    const {
      type
    } = (await handleOAuthSignOut(cognitoConfig, oAuthStore)) ?? {};
    if (type === 'error') {
      throw new AuthError({
        name: OAUTH_SIGNOUT_EXCEPTION,
        message: 'An error occurred when attempting to log out from OAuth provider.'
      });
    }
  } else {
    // complete sign out
    tokenOrchestrator.clearTokens();
    await clearCredentials();
    Hub.dispatch('auth', {
      event: 'signedOut'
    }, 'Auth', AMPLIFY_SYMBOL);
  }
}
async function clientSignOut(cognitoConfig) {
  try {
    const authTokens = await tokenOrchestrator.getTokenStore().loadTokens();
    assertAuthTokensWithRefreshToken(authTokens);
    if (isSessionRevocable(authTokens.accessToken)) {
      await revokeToken({
        region: getRegion(cognitoConfig.userPoolId),
        userAgentValue: getAuthUserAgentValue(AuthAction.SignOut)
      }, {
        ClientId: cognitoConfig.userPoolClientId,
        Token: authTokens.refreshToken
      });
    }
  } catch (err) {
    // this shouldn't throw
    logger.debug('Client signOut error caught but will proceed with token removal');
  }
}
async function globalSignOut(cognitoConfig) {
  try {
    const authTokens = await tokenOrchestrator.getTokenStore().loadTokens();
    assertAuthTokens(authTokens);
    await globalSignOut$1({
      region: getRegion(cognitoConfig.userPoolId),
      userAgentValue: getAuthUserAgentValue(AuthAction.SignOut)
    }, {
      AccessToken: authTokens.accessToken.toString()
    });
  } catch (err) {
    // it should not throw
    logger.debug('Global signOut error caught but will proceed with token removal');
  }
}
const isSessionRevocable = token => !!token?.payload?.origin_jti;
export { signOut };
