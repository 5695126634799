import {Injectable} from '@angular/core';
import {FileResponse, FileUploadResponse} from "../models/file";
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, QueryParams} from "@ngrx/data";
import {switchMap} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class FileEntityService extends EntityCollectionServiceBase<FileResponse> {

  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private httpClient: HttpClient,
  ) {
    super('Files', serviceElementsFactory);
  }

  upload(file: File, fileName: string, metadata: Record<string, string>) {

    const customerId = metadata['customerId'];
    const appointmentId = metadata['appointmentId'];
    const attachedObjectId = customerId ? `customerId#${customerId}` : appointmentId ? `appointmentId#${appointmentId}` : undefined;
    const request: Partial<FileResponse> = {
      mimeType: file.type,
      sizeInBytes: file.size,
      s3Key: fileName,
      attachedObjectId: attachedObjectId,
      uploadedBy: metadata['uploadedBy']
    }

    return this.add(request, {isOptimistic: false}).pipe(
      switchMap(response => {
        return this.httpClient.put((response as FileUploadResponse).presignedUploadUrl, file, {
          headers: {
            'Content-Type': file.type,
          },
        })
      }),
    )
  }

  subscribeWithQuery(queryParams: QueryParams) {
    this.getWithQuery(queryParams).subscribe();
    return this.entities$.pipe(
      map(entities => {
          return entities.filter(entity => {
            return entity.attachedObjectId === `customerId#${queryParams['customerId']}` ||
              entity.attachedObjectId === `appointmentId#${queryParams['appointmentId']}`;
          });
        }
      ));
  }
}
