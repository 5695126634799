import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(minutes: number | undefined): string {
    if (minutes === undefined) return '';

    const minutesInADay = 24 * 60;

    const fullDays = Math.floor(minutes / minutesInADay);
    const daysText = fullDays === 0 ? `` : fullDays === 1 ? `${fullDays} Tag` : `${fullDays} Tage`;

    const minutesWithoutFullDays = minutes - (fullDays * minutesInADay);
    const fullHours = Math.floor(minutesWithoutFullDays / 60);
    const hoursText = fullHours === 0 ? `` : fullHours === 1 ? `${fullHours} Stunde` : `${fullHours} Stunden`;

    const minutesWithoutFullHours = minutesWithoutFullDays - (fullHours * 60);
    const minutesText = minutesWithoutFullHours === 0 ? `` : `${minutesWithoutFullHours} Minuten`;
    return `${daysText} ${hoursText} ${minutesText}`;
  }

}

@Pipe({
  name: 'durationDays'
})
export class DurationDaysPipe implements PipeTransform {

  transform(days: number): string {

    const fullYears = Math.floor(days / 365);
    const yearsText = fullYears === 0 ? `` : fullYears === 1 ? `${fullYears} Jahr` : `${fullYears} Jahre`;

    const daysWithoutFullYears = days - (fullYears * 365);
    const fullMonths = Math.floor(daysWithoutFullYears / 30);
    const monthsText = fullMonths === 0 ? `` : fullMonths === 1 ? `${fullMonths} Monat` : `${fullMonths} Monate`;

    const daysWithoutFullMonths = daysWithoutFullYears - (fullMonths * 30);
    const daysText = daysWithoutFullMonths === 0 ? `` : `${daysWithoutFullMonths} Tage`;
    return `${yearsText} ${monthsText} ${daysText}`;
  }

}
