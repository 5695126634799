import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Customer, CustomerCreateRequest, transformCustomerName} from "@app/shared/models/customer";
import {debounceTime, Observable} from "rxjs";
import {BusyState} from "@app/shared/components/busy-indicator/busy-indicator.component";
import {CustomerEntityService} from "@app/shared/services/customer-entity.service";
import {map} from "rxjs/operators";
import {select, Store} from "@ngrx/store";
import {currentUserSelector, tenantFeatureConfigSelector} from "@app/shared/store/selectors";
import {AppointmentEntityService} from "../../../services/appointment-entity.service";
import {format, parseISO} from "date-fns";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AppointmentEntityWithRecurrences} from "@app/shared/models/appointment";

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss']
})
export class CustomerFormComponent implements OnInit {

  @Input() description: string;
  @Input() customer?: Customer
  @Output() closeDialog = new EventEmitter<CustomerCreateRequest>();
  @Output() deleteCustomer = new EventEmitter<string>();

  customerServiceBusy$: Observable<BusyState>;

  customerForm = new FormGroup({
    salutation: new FormControl<string | null>(null, [Validators.maxLength(256)]),
    firstName: new FormControl<string | null>(null, [Validators.maxLength(256)]),
    lastName: new FormControl<string | null>(null, [Validators.maxLength(256)]),
    companyName: new FormControl<string | null>(null, [Validators.maxLength(256)]),
    phoneNumber: new FormControl<string | null>(null, [Validators.maxLength(256)]),
    reminderSMS: new FormControl<boolean | null>(null),
    emailAddress: new FormControl<string | null>(null, [Validators.email, Validators.maxLength(256)]),
    reminderEmail: new FormControl<boolean | null>(null),
    birthday: new FormControl<Date | null>(null),
    notes: new FormControl<string | null>(null, [Validators.maxLength(3000)]),
    postalAddress: new FormGroup({
      addressLine1: new FormControl<string | null>(null, [Validators.maxLength(256)]),
      addressLine2: new FormControl<string | null>(null, [Validators.maxLength(256)]),
      zip: new FormControl<string | null>(null, [Validators.maxLength(256)]),
      city: new FormControl<string | null>(null, [Validators.maxLength(256)]),
      country: new FormControl<string | null>(null, [Validators.maxLength(256)]),
    })
  });
  tenantFeatureConfig$ = this.store.pipe(
    select(tenantFeatureConfigSelector),
  );
  currentUser$ = this.store.pipe(
    select(currentUserSelector)
  );

  constructor(
    customerEntityService: CustomerEntityService,
    private snackBar: MatSnackBar,
    private appointmentEntityService: AppointmentEntityService,
    private store: Store,
  ) {
    this.customerServiceBusy$ = customerEntityService.loading$.pipe(
      map(loading => ({busy: loading}))
    );
  }

  ngOnInit(): void {
    if (this.customer) {
      const birthday = this.customer.birthday ? parseISO(this.customer.birthday) : null;

      this.customerForm.setValue({
        companyName: this.customer.companyName || null,
        emailAddress: this.customer.emailAddress || null,
        firstName: this.customer.firstName || null,
        lastName: this.customer.lastName || null,
        notes: this.customer.notes || null,
        phoneNumber: this.customer.phoneNumber || null,
        birthday: birthday,
        postalAddress: {
          addressLine1: this.customer.postalAddress?.addressLine1 || null,
          addressLine2: this.customer.postalAddress?.addressLine2 || null,
          city: this.customer.postalAddress?.city || null,
          zip: this.customer.postalAddress?.zip || null,
          country: this.customer.postalAddress?.country || null,
        },
        salutation: this.customer.salutation || null,
        reminderSMS: this.customer.reminderSMS || false,
        reminderEmail: this.customer.reminderEmail || false
      })
    }

    if (!this.customer?.emailAddress)
      this.customerForm.controls.reminderEmail.disable();
    if (!this.customer?.phoneNumber)
      this.customerForm.controls.reminderSMS.disable();

    this.customerForm.controls.emailAddress.valueChanges.pipe(
      debounceTime(300),
    ).subscribe(() => {
        this.toggleReminderDisabled()
      }
    );
    this.customerForm.controls.phoneNumber.valueChanges.pipe(
      debounceTime(300),
    ).subscribe(() => {
      this.toggleReminderDisabled()
    });
  }

  onSubmit() {
    this.validateCreateCustomerRequest()
    if (!this.customerForm.valid) return;

    const formInput = this.customerForm.getRawValue();
    const request: CustomerCreateRequest = {
      ...formInput,
      birthday: formInput.birthday ? format(formInput.birthday, 'yyyy-MM-dd') : undefined,
      reminderEmail: !!(formInput.reminderEmail && formInput.emailAddress),
      reminderSMS: !!(formInput.reminderSMS && formInput.phoneNumber),
    } as CustomerCreateRequest;

    this.closeDialog.emit(request);
  }

  validateCreateCustomerRequest() {
    const formInput = this.customerForm.getRawValue();
    if (!formInput.firstName &&
      !formInput.lastName &&
      !formInput.emailAddress &&
      !formInput.phoneNumber &&
      !formInput.companyName &&
      !formInput.notes
    ) {
      this.customerForm.setErrors({missingRequiredField: true})
    }
  }

  openPrintPage() {

    const id = this.customer?.id;
    if (id)
      this.appointmentEntityService.findByCustomerIdWithRecurrences(id, {
        startDate: new Date(Date.UTC(2023, 1, 1)),
        endDate: new Date()
      }).subscribe(appointments => {
        const sorted = appointments.sort((a1, a2) => a1.start < a2.start ? -1 : 1);
        const lastFive = sorted.slice(Math.max(0, sorted.length - 5), sorted.length);
        this.print(lastFive);
      })
    else
      this.print([]);
  }

  private print(appointments: AppointmentEntityWithRecurrences[]) {
    const customer = this.customerForm.getRawValue() as unknown as Customer;

    const appointmentsHtml = appointments.map(it => `
    <div class="row my-2">
      <div class="col-12">${format(parseISO(it.start), 'dd.MM.yy')}</div>
      <div class="col-12">${it.title}</div>
      <div class="col-12">${it.priceInCent ? (it.priceInCent / 100) : 0} EUR</div>
      <div class="col-12">${it.notes ?? ''}</div>
    </div>
    `).join('')

    const printHtml = `<!DOCTYPE html>
    <html lang="de">
        <meta charset="UTF-8">
        <head>
            <title>Druckvorschau</title>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
        <style>
                .container {
                    font-size: 24px;
                    width: 72mm;
                    max-width: 72mm;
                }
        </style>
        </head>
        <body>
  <div class="container">
    <div class="row pb-2">
      <div class="col-12">${transformCustomerName(customer)}</div>
    </div>
    <div class="row pb-2">
      <div class="col-12">Tel. ${customer.phoneNumber || ''}</div>
    </div>
    <div class="row pb-2">
      <div class="col-12">${customer.postalAddress?.addressLine1 ?? ''}</div>
      <div class="col-12">${customer.postalAddress?.zip ?? ''} ${customer.postalAddress?.city ?? ''}</div>
      <div class="col-12">${customer.postalAddress?.country ?? ''}</div>
    </div>
    <div class="row pb-2">
      <div class="col-12">${customer.notes || ''}</div>
    </div>
    ${appointmentsHtml}
  </div>
  <script>window.print();</script>

        </body>
    </html>`;

    const objectURL = URL.createObjectURL(new Blob([printHtml], {type: 'text/html; charset=utf-8'}));

    window.open(objectURL);
  }

  copyToClipboard(text: string | undefined) {
    if (!text) return;

    navigator.clipboard.writeText(text).then(
      () => {
        this.snackBar.open(`In Zwischenablage kopiert: ${text}`, undefined, {duration: 3000});
      },
      (err) => {
        console.error('Error copying text to clipboard: ', err);
      });
  }

  toggleReminderDisabled() {
    if (this.customerForm.controls.emailAddress.value
      && this.customerForm.controls.emailAddress.valid) {
      this.customerForm.controls.reminderEmail.enable();
    } else {
      this.customerForm.controls.reminderEmail.disable();
    }

    if (this.customerForm.controls.phoneNumber.value
      && this.customerForm.controls.phoneNumber.valid) {
      this.customerForm.controls.reminderSMS.enable();
    } else {
      this.customerForm.controls.reminderSMS.disable();
    }
  }
}
