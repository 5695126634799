import { clearCredentials, Hub } from '@aws-amplify/core';
import { AMPLIFY_SYMBOL } from '@aws-amplify/core/internals/utils';
import '../refreshAuthTokens.mjs';
import '../../tokenProvider/errorHelpers.mjs';
import { tokenOrchestrator } from '../../tokenProvider/tokenProvider.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const completeOAuthSignOut = async store => {
  await store.clearOAuthData();
  tokenOrchestrator.clearTokens();
  await clearCredentials();
  Hub.dispatch('auth', {
    event: 'signedOut'
  }, 'Auth', AMPLIFY_SYMBOL);
};
export { completeOAuthSignOut };
