<div
  class="notification-container shadow-lg p-3 rounded"
  *ngIf="(filteredCustomers$ | async)?.length"
>
  <div class="notification-category">
    <h4 class="p-0 m-0">Geburtstage</h4>
    <div
      class="d-flex align-items-center justify-content-between"
      *ngFor="let customer of filteredCustomers$ | async"
    >
      <span class="editCustomerButton" (click)="editCustomer(customer)"
        >{{ customer.firstName }} {{ customer.lastName }}</span
      >
      <button
        mat-icon-button
        matTooltip="Als gelesen markieren"
        (click)="setBirthdayViewed(customer)"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>
