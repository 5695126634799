import {Injectable} from "@angular/core";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {Subject} from "rxjs";

@Injectable()
export class PaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();

  firstPageLabel = `Erste Seite`;
  itemsPerPageLabel = `Zeilen pro Seite:`;
  lastPageLabel = `Letzte Seite`;

  nextPageLabel = 'Nächste Seite';
  previousPageLabel = 'Vorherige Seite';

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return `Seite 1 von 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return `Seite ${page + 1} von ${amountPages}`;
  }
}
